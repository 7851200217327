var render = function() {
  var _vm = this
  var _h = _vm.$createElement
  var _c = _vm._self._c || _h
  return _c(
    "div",
    {
      directives: [
        {
          name: "show",
          rawName: "v-show",
          value: _vm.config.inited,
          expression: "config.inited"
        }
      ],
      staticClass: "Index"
    },
    [
      _c(
        "div",
        {
          directives: [
            {
              name: "show",
              rawName: "v-show",
              value:
                _vm.config.dashboardConfig !== null &&
                _vm.config.view === "dashboard",
              expression:
                "config.dashboardConfig !== null && config.view === 'dashboard'"
            }
          ]
        },
        [
          _vm.config.dashboardConfig &&
          _vm.config.dashboardConfig["navigation-position"] === "top"
            ? _c("NavigationBar", {
                ref: "NavigationBar",
                attrs: {
                  config: _vm.config,
                  localConfig: _vm.localConfig,
                  utils: _vm.utils,
                  routingID: _vm.routingID
                }
              })
            : _vm._e(),
          _vm._v(" "),
          _vm.config.dashboardConfig
            ? _vm._l(_vm.config.dashboardConfig.tabs, function(tab) {
                return _c("Dashboard", {
                  key: tab.title,
                  ref: "Dashboard",
                  refInFor: true,
                  attrs: {
                    "data-tab-title": tab.title,
                    visible: tab.title === _vm.localConfig.tab,
                    tab: tab,
                    config: _vm.config,
                    localConfig: _vm.localConfig,
                    utils: _vm.utils,
                    routingID: _vm.routingID
                  }
                })
              })
            : _vm._e(),
          _vm._v(" "),
          _vm.config.dashboardConfig &&
          _vm.config.dashboardConfig["navigation-position"] === "bottom"
            ? _c("NavigationBar", {
                ref: "NavigationBar",
                staticClass: "fixed bottom",
                attrs: {
                  config: _vm.config,
                  localConfig: _vm.localConfig,
                  utils: _vm.utils,
                  routingID: _vm.routingID
                }
              })
            : _vm._e()
        ],
        2
      ),
      _vm._v(" "),
      !_vm.config.dashboardConfig
        ? _c(
            "div",
            [
              _c("LoadingPudding", {
                ref: "LoadingPudding",
                attrs: {
                  config: _vm.config,
                  localConfig: _vm.localConfig,
                  utils: _vm.utils,
                  routingID: _vm.routingID
                }
              })
            ],
            1
          )
        : _vm._e(),
      _vm._v(" "),
      _vm.config.view === "configuration"
        ? _c("ConfigurationPanel", {
            ref: "ConfigurationPanel",
            attrs: {
              config: _vm.config,
              localConfig: _vm.localConfig,
              utils: _vm.utils,
              routingID: _vm.routingID
            }
          })
        : _vm._e()
    ],
    1
  )
}
var staticRenderFns = []
render._withStripped = true

export { render, staticRenderFns }